"use client"

import { AppRouterCacheProvider } from "ui/dist/utils/AppRouterCache"
import dynamic from "next/dynamic"
import type { ReactNode } from "react"

const ReactQuery = dynamic(() => import("./auth-and-trpc"), {
  ssr: false,
})
const RootSnackbarProvider = dynamic(() => import("./snackbar"), {
  ssr: false,
})
const RootThemeProvider = dynamic(() => import("./theme"), {
  ssr: false,
})

export default function AppProvider({ children }: { children: ReactNode }) {
  return (
    <AppRouterCacheProvider options={{ enableCssLayer: true }}>
      <RootThemeProvider>
        <RootSnackbarProvider>
          <ReactQuery>{children}</ReactQuery>
        </RootSnackbarProvider>
      </RootThemeProvider>
    </AppRouterCacheProvider>
  )
}
